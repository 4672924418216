import React from 'react'

// Components
import Quote from 'components/flex/Quote/Quote'
import QuoteFull from 'components/flex/Quote/QuoteFull'
import QuoteSlide from 'components/flex/Quote/QuoteSlide'

interface QuoteShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Quote
  location: any
}

interface QuoteProps {
  [key: string]: any
}

const QuoteShell: React.FunctionComponent<QuoteShellProps> = ({
  fields,
  location,
}) => {
  const quotes: QuoteProps = {
    default: Quote,
    full: QuoteFull,
    slide: QuoteSlide,
  }

  if (!fields.styleType || !quotes[fields.styleType]) {
    return null
  }

  const Component = quotes[fields.styleType]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default QuoteShell
