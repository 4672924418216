import React from 'react'
import Plaatjie from '@ubo/plaatjie'
import styled from 'styled-components'

// Components
import ParseContent from 'components/shared/ParseContent'

// Slider
import Slider from 'react-slick'
import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

// Images
import Left from 'img/slide-arrow-left.png'
import Right from 'img/slide-arrow-right.png'

const StyledQuote = styled.section`
  background-color: ${(props) => props.theme.color.face.main};
  min-height: 562px;
`

const Content = styled(ParseContent)`
  & p {
    color: ${(props) => props.theme.color.text.light};
    font-size: ${(props) => props.theme.font.size['25']};
    line-height: 40px;

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size['15']};
      line-height: 27px;
    }

    &:first-child {
      font-style: italic;
    }

    &:last-child {
      font-family: ${(props) => props.theme.font.family.secondary};
      font-size: ${(props) => props.theme.font.size['23']};

      @media (max-width: 991px) {
        font-size: ${(props) => props.theme.font.size['16']};
        line-height: 33px;
      }
    }
  }
`

const StyledImageBlock = styled(Plaatjie)`
  max-width: 185px;
  max-height: 126px;
  min-width: 185px;
  min-height: 126px;
  top: 0;
  position: relative;
  z-index: 1;

  & img {
    object-fit: contain !important;
  }
`

const StyledSlider = styled(Slider)`
  & button.slick-prev {
    @media (min-width: 992px) {
      left: -50px;
    }
    background: url(${Left}) no-repeat;
    width: 78px;
    height: 78px;

    &::before {
      display: none;
    }
  }

  & button.slick-next {
    @media (min-width: 992px) {
      right: -50px;
    }
    background: url(${Right}) no-repeat;
    width: 78px;
    height: 78px;

    &::before {
      display: none;
    }
  }
`

interface QuoteSlideProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Quote
}

const QuoteSlide: React.FC<QuoteSlideProps> = ({ fields }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    accessibility: false,
  }

  return (
    <StyledQuote
      id="testimonials"
      className="d-flex align-items-center mt-3 mt-lg-5"
    >
      <div className="container position-relative h-100">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StyledSlider {...settings}>
          {fields?.item?.map((item: any, index: number) => (
            <div key={index} className="d-flex align-items-center">
              <StyledImageBlock
                image={item?.image}
                alt=""
                className="position-absolute"
              />
              <Content
                content={item?.description}
                className="px-3 px-lg-5 pt-5"
              />
            </div>
          ))}
        </StyledSlider>
      </div>
    </StyledQuote>
  )
}

export default QuoteSlide
