import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledQuote = styled.section`
  background-color: ${(props) => props.theme.color.face.main};
  min-height: 562px;
`

const Content = styled(ParseContent)`
  & p {
    color: ${(props) => props.theme.color.text.light};
    font-size: ${(props) => props.theme.font.size['25']};
    line-height: 40px;

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size['18']};
      line-height: 33px;
    }

    &:first-child {
      font-style: italic;
    }

    &:last-child {
      font-family: ${(props) => props.theme.font.family.secondary};
      font-size: ${(props) => props.theme.font.size['23']};

      @media (max-width: 991px) {
        font-size: ${(props) => props.theme.font.size['16']};
        line-height: 33px;
      }
    }
  }
`

const StyledImageBlock = styled(Plaatjie)`
  max-width: 185px;
  max-height: 126px;
  min-width: 185px;
  min-height: 126px;
  top: -39px;
  position: relative;
  z-index: 1;

  & img {
    object-fit: contain !important;
  }
`

interface QuoteFullProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Quote
}

const QuoteFull: React.FC<QuoteFullProps> = ({ fields }) => (
  <StyledQuote
    id="testimonials"
    className="d-flex align-items-center mt-3 mt-lg-5"
  >
    <div className="container position-relative h-100">
      <StyledImageBlock
        image={fields?.image}
        alt=""
        className="position-absolute"
      />
      <Content content={fields.description} className="px-3 px-lg-5 pt-5" />
    </div>
  </StyledQuote>
)

export default QuoteFull
