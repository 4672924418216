import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)``

const StyledImageBlock = styled(Plaatjie)`
  max-width: 185px;
  max-height: 125px;
  min-width: 185px;
  min-height: 125px;
  top: -39px;
  opacity: 0.7;
  position: relative;
  z-index: -2;

  & img {
    object-fit: contain !important;
  }
`

interface QuoteProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Quote
}

const Quote: React.FC<QuoteProps> = ({ fields }) => (
  <section className="container position-relative my-5">
    <StyledImageBlock
      image={fields?.image}
      alt=""
      className="position-absolute"
    />
    <Content content={fields.description} className="p-5" />
  </section>
)

export default Quote
